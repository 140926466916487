<form>
  <div class="input-group mb-0">
    <input class="form-control  search-input" type="text"
      placeholder="{{ ( SearchName ? SearchName : 'Search' )| translate }}" [(ngModel)]="freeText"
      name="freeText" (keyup)="autoSearchByText.emit($event)" (change)="autoSearchByText.emit($event)">
    <button class="btn search-btn input-group-append" type="button" (click)="ClearSearch()"> <i
        class="fas fa-times text-grey"></i></button>
    <button class="btn search-btn input-group-append" (click)="searchByText()"> <i
        class="fas fa-search text-grey"></i></button>
  </div>
</form>
<!-- {{ 'SearchBookingViaMobilenumberClientDateEtc' | translate }} -->
